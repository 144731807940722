<template>
  <main class="page-main-bg">
    <v-container
      class="main-container team-container position-relative px-lg-12"
    >
      <!--tool-bar-->
      <tool-bar
        :breadcrumbs="breadcrumbs"
        :length="allTaxInvoices.length"
        subTilte="فاتورة"
        title="الفواتير الضريبية"
      >
        <slot />
      </tool-bar>

      <!------------------------------------------->
      <!-------------filters----------------------->
      <!-------------------------------------------->

      <div class="d-flex justify-space-between align-start mb-2 mt-6">
        <div
          class="d-flex flex-column justify-start align-start"
          :class="{ 'w-100': xs }"
        >
          <div class="d-flex mb-5" v-if="!statusRestore">
            <div
              v-if="!isCustomer"
              class="switcher d-flex align-center justify-space-around py-1 px-1"
              :class="{ 'w-100': xs }"
            >
              <span
                :class="{ active: option.active, 'my-2': xs }"
                :key="i"
                @click="handleTaxInvoiceType(option.code)"
                class="choices font-weight-medium font-14 px-6 pb-1"
                v-for="(option, i) in TaxOptions"
              >
                {{ option.nameAr }}
              </span>
            </div>
            <template v-if="!sm && !isCustomer">
              <div class="users-filter mx-2">
                <autocomplete
                  :itemData="true"
                  :items="AllClients"
                  :noData="noData"
                  :placeholder="selectedClientPlaceholder"
                  :returnObject="true"
                  @nextPageTrigger="getClientNextData"
                  @emitSearchKey="getClientDataBySearchKey"
                  :hideDetails="true"
                  :rules="[true]"
                  :loading="clientIsLoading"
                  :value="taxInvoicesFilter.owner"
                  @input="setFilterData('owner', $event)"
                />
              </div>
              <div class="users-filter">
                <autocomplete
                  :itemData="true"
                  :items="AllRenters"
                  :noData="noData"
                  :placeholder="selectedRnterPlaceholder"
                  :returnObject="true"
                  @nextPageTrigger="getRenterNextData"
                  @emitSearchKey="getRenterDataBySearchKey"
                  :hideDetails="true"
                  :rules="[true]"
                  :loading="renterIsLoading"
                  :value="taxInvoicesFilter.renter"
                  @input="setFilterData('renter', $event)"
                />
              </div>
              <div class="realestates-date-filter mx-2">
                <autocomplete
                  :itemData="true"
                  itemText="propertyName"
                  :items="allProperties"
                  :noData="noProperties"
                  :placeholder="selectedPropertyPlaceholder"
                  :returnObject="true"
                  @nextPageTrigger="getPropertyNextData"
                  @emitSearchKey="getPropertyDataBySearchKey"
                  :hideDetails="true"
                  :rules="[true]"
                  :loading="propertyIsLoading"
                  :value="taxInvoicesFilter.propertyName"
                  @input="setFilterData('propertyName', $event)"
                />
              </div>
            </template>
            <div v-if="!sm || isCustomer" class="realestates-date-filter">
              <v-menu
                v-model="datePickerModal"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    append-icon="mdi-calendar-month-outline"
                    dense
                    filled
                    hide-details
                    placeholder="تاريخ الإنشاء"
                    readonly
                    required
                    v-bind="attrs"
                    v-on="on"
                    :value="taxInvoicesFilter.date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="taxInvoicesFilter.date"
                  @input="setFilterData('date', $event)"
                ></v-date-picker>
              </v-menu>
            </div>
            <div v-if="isCustomer" class="max-width-220 mx-2">
              <v-text-field
                height="40"
                dense
                filled
                placeholder="ابحث بإسم العقار أو الوحدة"
                :hide-details="true"
                v-model="propertyNameSearch"
                @input="searchForRealEstates"
              />
            </div>
          </div>
          <!------------------------------------>
          <!----MultipleSelectionsForDelete----->
          <!------------------------------------>

          <MultipleSelectionsForDelete
            v-bind="multipleSelectionsData"
            :showRestoreDeleted="false"
            @changeSelect="selectAllChanged"
            @switched="switched"
          />
        </div>
        <div class="pa-0 d-flex" v-if="!sm">
          <h6 class="font-weight-light ml-2">ترتيب بواسطة</h6>
          <v-menu
            content-class="vMenuFilter elevation-0"
            offset-y
            ref="dateMenu"
            transition="slide-y-transition"
            width="150"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-center">
                <v-slide-x-transition>
                  <v-card
                    class="d-flex justify-space-between px-2"
                    v-bind="attrs"
                    v-on="on"
                    width="110"
                  >
                    <h6 class="mr-2 font-14">
                      {{ filterQuery.sort.nameAr }}
                    </h6>
                    <v-icon size="20"
                      >{{
                        menuStateSwitcher($refs.dateMenu)
                          ? 'mdi-chevron-up'
                          : 'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-card>
                </v-slide-x-transition>
              </div>
            </template>
            <v-list
              class="pa-0"
              v-for="(option, i) in sortOptions"
              :key="i"
              :width="110"
            >
              <v-list-item-group>
                <v-list-item class="px-1">
                  <v-list-item-title
                    @click="handleSortChange(option)"
                    class="font-14 mr-1 mb-2 font-weight-medium"
                  >
                    {{ option.nameAr }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>

      <!------- TaxInvoice Card ------->
      <div v-if="allTaxInvoices.length">
        <taxInvoiceCard
          v-for="tax in allTaxInvoices"
          :item="tax"
          :key="tax._id"
          @editApprovedTax="editApprovedTax"
          @editDraftTax="editDraftTax"
          @exportTax="exportTax"
          @exportPdfTax="exportPdfTax"
          @showDetails="setDetails"
          @showVersions="showSideVersions"
          @sharePdf="sharePdf"
          @deleteTax="deleteTax"
          class="relative set__hover"
        >
          <v-checkbox
            v-if="
              filterType !== 'cancelled' &&
              $hasPermission('properties', 'taxBills', 'delete')
            "
            :class="[
              'reset-all ma-0 checkbox checkbox__reports',
              { checked: tax.checked }
            ]"
            dense
            hide-details
            v-model="tax.checked"
          />
        </taxInvoiceCard>
      </div>

      <!--Loader-->
      <loader v-else-if="isPageLoading" v-bind="loaderProps" />

      <!--no taxes-->
      <no-data v-else v-bind="noDataProps" />

      <!------------- Edit Tax Model ------------>
      <editTaxInvoice
        v-if="editTaxModelStatus"
        v-bind="{ editTaxModelStatus, selectedTaxId }"
        @addPaymentNote="addPaymentNote"
        @closeModel="changeEditTaxVisibilty(false)"
        @showTax="showTax"
      />

      <!--sideDetailes-->
      <v-layout v-show="sideDetails">
        <sideTaxInvoiceDetails
          :item="taxInvoice"
          :sideDetails="sideDetails"
          @closeDetails="sideDetails = false"
          @showDetails="setDetails"
          @editApprovedTax="editApprovedTax"
          @editDraftTax="editDraftTax"
          @deleteTax="deleteTax"
          @closeAddModelsEdit="exportPdfTax"
          @exportTax="exportTax"
        />
      </v-layout>

      <!-- freeTaxInvoice-->
      <add-tax-invoices
        v-if="dialogState"
        v-bind="addTaxInvoiceProps"
        @resetValidationStatusToggeling="toggleResetValidationStatus"
        @exportPdfTax="exportPdfTax"
        @showDetails="setDetails"
        @resetTaxToBeEdit="resetTaxToBeEdit"
        @refreshTaxes="getAllTaxBills"
        @close-tax-dialog="closeTaxDialog"
      />

      <!-- versionDetails -->
      <taxSideVersions
        v-if="isSideVersionsVisible"
        :tax="currentSelectedTax"
        @showVersions="showSideVersions"
        @showDetails="setDetails"
        @exportPdfTax="exportPdfTax"
        @deleteTax="deleteTax"
        @editApprovedTax="editApprovedTax"
      />

      <!---Tax PDF--->
      <taxInvoicePdf
        v-if="pdfStatus"
        ref="PDF"
        :tax="taxInvoice"
        @openPdf="changePdfVisibilty(true)"
        @closePdf="changePdfVisibilty(false)"
      />
      <!--
        commented this i don't know why we need two pdf to show but commented solve the problem of showing
      two pdf in responsive mode
      -->

      <!-- <taxInvoicePdf
        :tax="taxInvoice"
        :downloadPdfMarkup="false"
        :showHeader="false"
        v-show="pdfStatus"
      /> -->

      <!--Pagination-->
      <div class="text-center mt-6 mb-5" v-if="pagination.pagesCount > 1">
        <Pagination :pagination="pagination" @onChange="getAllTaxBills()" />
      </div>

      <!-------FastActions---------->

      <div class="d-flex align-center justify-end ml-md-8 fast-actions">
        <v-btn
          v-if="!isCustomer"
          @click="openAddTaxModel"
          class="font-weight-bold fast-actions-btn pa-5 font-12"
          color="primary"
          large
        >
          <img
            class="mx-2"
            src="@/assets/img/svgs/001-writing.svg"
            loading="lazy"
          />
          <span class="mb-1 mobile__mb__0">فاتورة حرة</span>
        </v-btn>

        <fastActions :class="{ 'mb-5': !hasPermission }" />
      </div>
    </v-container>
  </main>
</template>

<script>
import sideTaxInvoiceDetails from '@/components/TaxInvoices/sideTaxInvoiceDetails'
import taxInvoiceCard from '@/components/TaxInvoices/taxInvoiceCard.vue'
import autocomplete from '@/components/forms/autocomplete'
import MultipleSelectionsForDelete from '@/components/helper/MultipleSelectionsForDelete'
import fastActions from '@/components/helper/fastActions'
import loader from '@/components/helper/loader.vue'
import noData from '@/components/helper/noData.vue'
import Pagination from '@/components/helper/pagination.vue'
import ToolBar from '@/components/listing/ToolBar'
import config from '@/config'
import { debounce } from '@/helpers/debounce'
import confirmationMixin from '@/mixins/confirmationMixin.js'
import generateShareCopy from '@/mixins/generateShareCopy'
import multipleSelections from '@/mixins/multipleSelections'
import {
  EnvService,
  MultipleDeletion,
  bondsService,
  realEstateService
} from '@/services'
import { taxBillsService } from '@/services/taxBills.service.js'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  mixins: [multipleSelections, generateShareCopy, confirmationMixin],
  components: {
    AddTaxInvoices: () => import('@/components/modals/AddTaxInvoices'),
    fastActions,
    ToolBar,
    taxInvoiceCard,
    sideTaxInvoiceDetails,
    editTaxInvoice: () => import('@/components/modals/editTaxInvoice.vue'),
    taxInvoicePdf: () => import('@/components/TaxInvoices/taxInvoicePdf'),
    taxSideVersions: () => import('@/components/TaxInvoices/taxSideVersions'),
    Pagination,
    autocomplete,
    MultipleSelectionsForDelete,
    loader,
    noData
  },
  name: 'taxInvoices',
  data() {
    return {
      noDataConfig: {
        text: 'لا توجد فواتير متاحة للعرض حتى الان',
        subTitle:
          'ستكون كل الفواتنير الصادرة متاحة في هذا الثقائمة مع إمكانية <br /> العرض والمشاركة والتحصيل',
        icon: require('@/assets/img/tax-invoices/taxInvoiceIcon.png')
      },
      pdfStatus: false,
      isPageLoading: false,
      clientIsLoading: false,
      renterIsLoading: false,
      selectedClientPlaceholder: 'المالك',
      noData: 'لا يوجد عملاء متاحة لهذا البحث',
      selectedRnterPlaceholder: 'المستأجر',
      resetValidationStatus: false,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 5,
        count: 0
      },
      clientSearchKey: '',
      clients: [],
      AllClients: [],
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      renterSearchKey: '',
      renters: [],
      AllRenters: [],
      paginationRenter: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      dialogState: false,
      taxInvoice: {},
      sideDetails: false,
      editTaxModelStatus: false,
      close: require('@/assets/img/svgs/closePopup.svg'),
      ShowFilterStatus: false,
      sortType: '',
      filterQuery: {
        types: undefined,
        sort: {
          nameAr: 'الأحدث',
          nameEn: 'desc'
        }
      },
      sortOptions: [
        { nameAr: 'الأحدث', nameEn: 'desc' },
        { nameAr: 'الأقدم', nameEn: 'asc' }
      ],
      TaxOptions: [],
      allTaxInvoices: [],
      isSideVersionsVisible: false,
      currentSelectedTax: {},
      taxToBeEdit: {},
      selectedTaxId: '',
      filterType: '',
      makeTaxApprovedSucceded: null,
      taxInvoicesFilter: {},

      // property autocomplete
      propertySearchKey: '',
      properties: [],
      allProperties: [],
      property: {},
      noProperties: 'لا توجد عقارات متاحه لهذا البحث',
      selectedPropertyPlaceholder: 'اسم العقار',
      propertyIsLoading: false,
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      datePickerModal: false,
      date: '',
      propertyNameSearch: ''
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    handleOpenSideDetailsUsingNewTab() {
      if (!Object.keys(this.taxInvoice).length && !!this.localStorageTax) {
        console.log('enter')
        this.setDetails({
          visible: true,
          taxInvoice: this.localStorageTax
        })
      }
    },
    handlingTaxesRoutes() {
      if (!!this.$router.currentRoute.query.item) {
        const { visible, item } = { ...this.$router.currentRoute.query }
        visible
          ? this.setDetails({ visible, taxInvoice: JSON.parse(item) })
          : this.exportPdfTax({ tax: JSON.parse(item) })
      }
    },
    /* Multiple selections */
    // ------------------ //
    // Delete pils with multiple selections
    async deleteItems() {
      const message = this.showSelectAll
        ? `هل انت متاكد من حذف الفواتير الضريبية وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من حذف الفاتورة الضريبية'
      // 1 - Open popup and confirm delete or not
      const status = await this.$root.$confirm.open({
        title: this.deleteTitle,
        message,
        options: {
          color: 'red'
        }
      })

      // 2 - If confirmed
      if (status) {
        try {
          const body = {
            environment: this.currentEnv._id,
            ids: this.getAllChecked
          }

          await MultipleDeletion.deleteTaxInvoices(body)
          this.getAllTaxBills()
          this.addNotification({
            text: 'تم الحذف بنجاح',
            color: 'success'
          })
        } catch {
          this.addNotification({
            text: 'حدث خطا يرجى المحاولة مره اخرى',
            color: 'error'
          })
        }
      }
    },
    // Restore pils with multiple selections
    async restoreDelete() {
      const message = this.showSelectAll
        ? `هل انت متاكد من استرجاع الفواتير الضريبية المحذوفة وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من استرجاع الفاتورة الضريبية المحذوفة'
      //
      const status = await this.$root.$confirm.open({
        title: this.restoreTitle,
        statusRestore: true,
        message,
        options: {
          color: 'red'
        }
      })
      console.log(status)
    },
    // This will handle all cases switching
    switched(data) {
      const { name, value } = data
      this.statusRestore = value

      if (name === 'deleted') {
        return this.allDeletedTaxes()
      }
      return this.getAllTaxBills()
    },
    allDeletedTaxes() {
      console.log('All delete taxinvoices')
    },
    handleSortChange(sort) {
      this.filterQuery = {
        ...this.filterQuery,
        sort
      }
      this.sortType = this.filterQuery.sort.nameEn
      this.getAllTaxBills()
    },
    getClientDataBySearchKey(e) {
      if (e) {
        this.clientSearchKey = e
        this.loadClients()
        this.clientIsLoading = true
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.clientIsLoading = true
        this.loadClients()
      }
    },
    getRenterDataBySearchKey(e) {
      if (e) {
        this.renterSearchKey = e
        this.loadRenters()
        this.renterIsLoading = true
      }
    },
    getRenterNextData() {
      if (this.paginationRenter.pageNumber < this.paginationRenter.pagesCount) {
        this.paginationRenter.pageNumber += 1
        this.loadRenters()
      }
    },
    getAllTaxBills() {
      this.isPageLoading = true
      let query = `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}`
      const taxSelectedType = this.activeTaxSelection
        ? this.activeTaxSelection?.code
        : 'approved'
      if (this.activeTaxSelection?.code !== 'cancelled') {
        query += `&environment=${this.currentEnv._id}&status=${taxSelectedType}&deleted=false`
      } else {
        query += `&environment=${this.currentEnv._id}&deleted=1`
      }

      Object.entries(
        this.isCustomer ? this.customerFilter : this.taxInvoicesFilter
      ).forEach(([key, value]) => {
        const filterVal = typeof value === 'string' ? value : value.filterVal
        query += `&${key}=${filterVal}`
      })

      if (this.sortType) {
        query += `&sort=${this.sortType}`
      }
      taxBillsService
        .getAllTaxBills(query)
        .then((res) => {
          this.allTaxInvoices = res.data.taxBills.map((tax) => ({
            ...tax,
            checked: false
          }))
          this.isPageLoading = false
          this.pagination.pagesCount = Math.ceil(
            res?.data?.count / this.pagination.pageSize
          )
          this.pagination.count = res?.data?.count ?? 0
          if (
            this.allTaxInvoices.length === 0 &&
            this.pagination.pageNumber !== 1
          ) {
            this.pagination.pageNumber--
            this.getAllTaxBills()
          }
        })
        .catch(() => {
          this.addNotification({
            text: 'لم نتمكن من تحميل الفواتير في الوقت الحالي',
            color: 'error'
          })
        })
    },
    async makeTaxApproved(id) {
      try {
        const query = {
          status: 'approved',
          environment: this.currentEnv._id
        }
        const { data } = await taxBillsService.updateTaxBillStatus(query, id)
        data.needsApproval
          ? this.showConfirmationPopupMixin()
          : (this.makeTaxApprovedSucceded = true)
      } catch {
        this.makeTaxApprovedSucceded = false
        this.addNotification({
          text: 'خطأ في تحويل الفاتورة الى سارية الرجاء المحاوله مجددا',
          color: 'error'
        })
      }
      if (this.makeTaxApprovedSucceded) {
        this.getAllTaxBills()
      }
    },
    closeTaxDialog() {
      this.resetTaxToBeEdit()
      this.dialogState = false
    },
    handleTaxInvoiceType(code) {
      this.TaxOptions.map((T) => {
        if (T.code === code) {
          T.active = true
        } else {
          T.active = false
        }
      })
      this.filterType = code
      this.pagination.pageNumber = 1
      this.getAllTaxBills()
    },
    exportPdfTax({ tax }) {
      this.sideDetails = false
      this.pdfStatus = true
      this.isSideVersionsVisible = false
      this.taxInvoice = tax
    },
    exportTax({ taxInvoice, fromDetails }) {
      fromDetails && this.setDetails({ visible: false, taxInvoice: null })
      this.$root.$confirm
        .open({
          title: 'تنبيه سيتم تحويل الفاتورة من مسوده إلي فاتورة سارية',
          message:
            'عند تصدير الفاتورة من مسوده الي سارية لن تتمكن من تعديلها مره أخري الا في حاله اصدار فاتورة اخري',
          options: {
            color: 'primary',
            width: 400,
            zIndex: 200
          },
          confitmTxt: 'تأكيد',
          img: {
            path: require('@/assets/img/svgs/exclamationMark.svg'),
            width: 25,
            height: 56
          }
        })
        .then(async (confirm) => {
          if (confirm) {
            await this.makeTaxApproved(taxInvoice._id)

            if (this.makeTaxApprovedSucceded) {
              const title = taxInvoice.realEstate?.name
                ? `تم إصدار فاتورة سارية لعقار ${taxInvoice.realEstate?.name}`
                : 'تم إصدار فاتورة سارية بنجاح'
              const message = taxInvoice.realEstate?.name
                ? `تم اصدار فاتورة سارية رقم ${taxInvoice.number} لعقار ${taxInvoice.realEstate?.name}`
                : `تم اصدار فاتورة سارية رقم ${taxInvoice.number}`
              this.$root.$confirm
                .open({
                  title,
                  message,
                  options: {
                    color: 'primary',
                    width: 400,
                    zIndex: 200
                  },
                  confirmTxt: 'بي دي اف',
                  img: {
                    path: require('@/assets/img/svgs/exported.svg'),
                    width: 55,
                    height: 55
                  },
                  cancelButtonStatus: false
                })
                .then((pdfTrue) => {
                  if (pdfTrue) {
                    this.taxInvoice = { ...taxInvoice, status: 'approved' }
                    this.pdfStatus = true
                  }
                })
            }
          }
        })
    },
    deleteTax(taxInvoice) {
      this.isSideVersionsVisible = false
      this.$root.$confirm
        .open({
          title: 'تنبيه سيتم حذف الفاتورة',
          message: 'عند حذف الفاتورة لن تتمكن من استرجاعها مره اخري',
          options: {
            color: 'red',
            width: 400,
            zIndex: 200
          },
          confirmTxt: 'تأكيد',
          img: {
            path: require('@/assets/img/svgs/closeImg.svg'),
            width: 56,
            height: 56
          }
        })
        .then((confirm) => {
          if (confirm) {
            const query = {
              deleted: true
            }
            taxBillsService
              .deleteTaxBill(query, taxInvoice._id)
              .then(({ data }) => {
                data.needsApproval && this.showConfirmationPopupMixin()
                this.addNotification({
                  text: 'تم حذف الفاتورة بنجاح',
                  color: 'success'
                })
                this.getAllTaxBills()
              })
          }
        })
    },
    editApprovedTax(tax) {
      this.isSideVersionsVisible = false
      this.selectedTaxId = tax._id
      this.taxInvoice = tax
      this.changeEditTaxVisibilty(true)
    },
    editDraftTax(e) {
      this.dialogState = !this.dialogState
      this.taxToBeEdit = e
    },
    setDetails({ visible, taxInvoice }) {
      this.sideDetails = visible
      this.taxInvoice = taxInvoice
      visible
        ? this.$router.replace({
            path: '/dashboard/taxInvoices',
            query: { taxInvoice: taxInvoice.number }
          })
        : this.$router.replace({ path: '/dashboard/taxInvoices' })
    },
    menuStateSwitcher(menu) {
      return menu?.isActive
    },
    async loadClients(query = '') {
      let data = 'ownersOnly=1&'
      if (this.clientSearchKey) {
        data += `&search=${this.clientSearchKey}`
      } else {
        data += `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      }
      const res = await EnvService.getAllClients(
        this.currentEnv._id,
        query,
        data
      )
      this.paginationClient.pagesCount = Math.ceil(
        res?.data?.count / this.paginationClient.pageSize
      )
      this.paginationClient.count = res?.data?.count ?? 0
      this.clients = res?.data?.users ?? []
      this.AllClients = [...this.AllClients, ...this.clients]
      this.clientIsLoading = false

      // Filter by client
      if (this.getClientTypeForRoute === 'owner' && this.$route.query.id) {
        this.AllClients.push(this.getClientForRoute)
        this.setFilterData('owner', this.getClientForRoute)
      }
    },
    async loadRenters(query) {
      let data = 'rentersOnly=1&'
      if (this.clientSerenterSearchKeyarchKey) {
        data += `&search=${this.renterSearchKey}`
      } else {
        data += `pageSize=${this.paginationRenter.pageSize}&pageNumber=${this.paginationRenter.pageNumber}`
      }
      const res = await EnvService.getAllClients(
        this.currentEnv._id,
        query,
        data
      )
      this.paginationRenter.pagesCount = Math.ceil(
        res?.data?.count / this.paginationRenter.pageSize
      )
      this.paginationRenter.count = res?.data?.count ?? 0
      this.renters = res?.data?.users ?? []
      this.AllRenters = [...this.AllRenters, ...this.renters]
      this.renterIsLoading = false

      // Filter by client
      if (this.getClientTypeForRoute === 'renter' && this.$route.query.id) {
        this.AllRenters.push(this.getClientForRoute)
        this.setFilterData('renter', this.getClientForRoute)
      }
    },
    showSideVersions({ visible, taxInvoice }) {
      this.isSideVersionsVisible = visible
      this.currentSelectedTax = taxInvoice
    },
    openAddTaxModel() {
      if (!this.hasPermission) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة فواتير',
          color: 'error'
        })
        return
      }
      this.resetValidationStatus = !this.resetValidationStatus
      this.dialogState = !this.dialogState
    },
    // When click on share internal and external button will trigger this function
    sharePdf(data) {
      const payload = {
        entityType: 'taxBill',
        entityId: data._id
      }
      const config = {
        type: 'الفاتورة الضريبية',
        component: 'taxInvoicePdf',
        service: bondsService
      }
      this.generateShareCopy(payload, config)
    },
    changePdfVisibilty(visible) {
      this.pdfStatus = visible
    },
    changeEditTaxVisibilty(visible) {
      this.editTaxModelStatus = visible
    },
    toggleResetValidationStatus() {
      this.resetValidationStatus = !this.resetValidationStatus
    },
    resetTaxToBeEdit() {
      this.taxToBeEdit = {}
    },
    addPaymentNote({ taxId, paymentNote }) {
      const taxIndex = this.allTaxInvoices.findIndex((tax) => tax._id === taxId)
      this.allTaxInvoices[taxIndex].paymentNotes.push(paymentNote)
    },
    showTax(type) {
      this.editTaxModelStatus = false
      type === 'pdf' ? this.changePdfVisibilty(true) : (this.sideDetails = true)
    },
    loadProperties() {
      let data = ''
      if (this.propertySearchKey) {
        data += `&search=${this.propertySearchKey}`
      } else {
        data += `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}`
      }
      const filters = {
        account: this.currentEnv?.owner?.id
      }

      realEstateService.getRealEstates(filters, data).then((res) => {
        this.paginationProperty.pagesCount = Math.ceil(
          res?.data?.count / this.paginationProperty.pageSize
        )
        this.paginationProperty.count = res?.data?.count ?? 0
        this.properties = res?.data?.realEstates ?? []
        this.allProperties = [...this.allProperties, ...this.properties]
        this.propertyIsLoading = false
      })
    },
    getPropertyNextData() {
      if (
        this.paginationProperty.pageNumber < this.paginationProperty.pagesCount
      ) {
        this.paginationProperty.pageNumber += 1
        this.propertyIsLoading = true
        this.loadProperties()
      }
    },
    hadnleRouteTaxInvoices() {
      if (this.$route.query.visible) {
        this.filterType = 'draft'
        this.TaxOptions = this.filteredTaxInvoiceOptions.map((opt) => ({
          ...opt,
          active: opt.code === 'draft'
        }))
        const taxtDetails = JSON.parse(
          localStorage.getItem('sideDetailsItemss')
        )
        this.setDetails({ visible: true, taxInvoice: taxtDetails })
        localStorage.removeItem('sideDetailsItemss')
      } else {
        this.TaxOptions = this.filteredTaxInvoiceOptions
      }
    },
    getPropertyDataBySearchKey(e) {
      if (e) {
        this.propertySearchKey = e
        this.loadProperties()
        this.propertyIsLoading = true
      }
    },
    formatFilterObject(key, val) {
      switch (key) {
        case 'owner':
        case 'renter':
          return { ...val, filterVal: val._id }
        case 'propertyName':
          return { ...val, filterVal: val.propertyName }
      }
    },
    setFilterData(key, val) {
      key === 'date' && (this.datePickerModal = false)
      this.pagination.pageNumber = 1
      if (val) {
        this.taxInvoicesFilter = {
          [`${key}`]:
            typeof val === 'string' ? val : this.formatFilterObject(key, val)
        }
        this.getAllTaxBills()
      } else {
        this.taxInvoicesFilter = {}
        this.getAllTaxBills()
      }
    },
    searchForRealEstates: debounce(function () {
      this.pagination.pageNumber = 1
      this.getAllTaxBills()
    }, 600)
  },
  watch: {
    customerDefaultRole() {
      this.pagination.pageNumber = 1
      this.getAllTaxBills()
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'customerDefaultRole', 'currentEnv']),
    ...mapGetters('appState', ['getClientForRoute', 'getClientTypeForRoute']),
    ...mapGetters('accountState', ['isCustomer']),
    localStorageTax() {
      return JSON.parse(localStorage.getItem('sideDetailsItem'))
    },
    hasPermission() {
      return this.$hasPermission('properties', 'taxInvoices', 'add')
    },
    noDataProps() {
      return {
        noDataText: this.noDataConfig.text,
        noDataSubTitle: this.noDataConfig.subTitle,
        noDataIcon: this.noDataConfig.icon
      }
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    md() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly
    },
    taxToBeEditValid() {
      return !(
        Object.keys(this.taxToBeEdit).length === 0 &&
        this.taxToBeEdit.constructor === Object
      )
    },
    breadcrumbs() {
      return [
        { text: 'المالية ', disabled: true },
        { text: this.activeTaxSelection?.nameAr, disabled: true }
      ]
    },
    filteredTaxInvoiceOptions() {
      return !this.isCustomer
        ? config.taxInvoiceOptions
        : config.taxInvoiceOptions.filter((opt) => opt.code != 'draft')
    },
    activeTaxSelection() {
      return this.TaxOptions.find((T) => T.active)
    },
    /* Multiple selections */
    // ------------------ //
    deleteTitle() {
      return this.showSelectAll ? 'حذف الفواتير' : 'حذف الفاتورة'
    },
    restoreTitle() {
      return this.showSelectAll
        ? 'استرجاع الفواتير المحذوفة'
        : 'استرجاع الفاتورة المحذوفة'
    },
    addTaxInvoiceProps() {
      return {
        dialogState: this.dialogState,
        taxToBeEdit: this.taxToBeEdit,
        taxToBeEditValid: this.taxToBeEditValid,
        resetValidationStatus: this.resetValidationStatus
      }
    },
    customerFilter() {
      const filters = { [`${this.customerDefaultRole}`]: this.user._id }
      if (this.propertyNameSearch) {
        filters.propertyName = this.propertyNameSearch
      }
      if (this.taxInvoicesFilter.date) {
        filters.date = this.taxInvoicesFilter.date
      }
      return filters
    }
  },
  created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    this.loadClients()
    this.loadRenters()
    this.loadProperties()
    this.hadnleRouteTaxInvoices()
    this.getAllTaxBills()
    this.handlingTaxesRoutes()
    this.handleOpenSideDetailsUsingNewTab()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
@import '@/styles/material-dashboard/_statistics-cards.scss';

.filter {
  ::v-deep {
    ::placeholder {
      color: #101010 !important;
    }
    :-ms-input-placeholder {
      color: #101010 !important;
    }
    ::-ms-input-placeholder {
      color: #101010 !important;
    }
  }
}

.users-filter {
  max-width: 120px;
}

.realestates-date-filter {
  max-width: 150px;
  ::v-deep {
    input {
      font-size: 14px !important;
    }
  }
}

.vMenuFilter {
  border-radius: 10px !important;
}

.switcher {
  background: #fff;
  border-radius: 10px;

  .active {
    border-radius: 10px;
    background: rgba($primary-color, 0.1);
    color: $primary-color !important;
    font-weight: 500;
  }

  .choices {
    cursor: pointer;
    color: #101010;
  }
}
</style>
